import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useLocale } from '../components/hooks/useLocale'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'

const WhereToBuy = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom,
        allContentfulPageCustom: {
            nodes
        }
    }
}) => {
    // const locale = useLocale()
    // let destini
    // switch (locale) {
    //     case 'en-US':
    //         destini = '//destinilocators.com/tazo/site/locator.php?RFR=https://www.tazo.com&&DEFLANG=4'
    //         break;
    //     case 'es-US':
    //         destini = '//destinilocators.com/tazospanish/site/locator.php?RFR=https://www.tazo.com&DEFLANG=4'
    //         break;
    //     default:
    //         destini = '//destinilocators.com/tazo/site/locator.php?RFR=https://www.tazo.com&&DEFLANG=4'
    //         break;
    // }
    const { seoTitle, seoDescription, slug, node_locale, snippet } = contentfulPageCustom
    const lang = useUrl()
    useEffect(() =>{
        const script = document.createElement('script')
        script.innerHTML = ` if(PriceSpider) PriceSpider.rebind();`
        document.body.appendChild(script)
        return () =>{document.body.removeChild(script)}
    })

    // const psContainer = document.getElementsByClassName('ps-widget')
    // console.log(psContainer, "pas");
    
    // if(!psContainer){
    //     return <div> ...Loading </div>
    // }
    return (
        <>
            <Seo
                slug={slug}
                title={seoTitle}
                description={seoDescription && seoDescription.seoDescription}
                node_locale={pageContext.node_locale}
                lang={lang}
                hrefLangs={nodes}
                store = {true}
            />
            {/* <div className='destini-where-to-buy'>
                <iframe id="destini" src={destini} scrolling="no" allow="geolocation" frameBorder="0" style={{width: "100%", height: "500px"}}>tazo product locator. Your browser does not support iframes.
                </iframe>
            </div> */}
            <div style={{margin: '2rem auto 0', minHeight:'70vh'}}>
                <div className="ps-widget" ps-sku></div>
            </div>
        </>
    )
}
export const PageWhereToBuyQuery = graphql`
    query pageWhereToBuy($id:String!, $contentful_id: String! ){
        contentfulPageCustom(id:{eq:$id}){
            seoTitle
            seoDescription{
                seoDescription
            }
            node_locale
            slug
            snippet
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id},  slug: { ne: null }}){
            nodes {
                slug
                node_locale
            }
        }
    }
`

export default WhereToBuy